.react-daterange-picker__wrapper {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
  border: unset !important;
}

table tr{
  height: 68px;
}


.editable-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: #525f7f;
  background-clip: padding-box;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: text; 
}

.editable-daterange-picker{
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: #525f7f;
  background-clip: padding-box;
  box-shadow: none;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: text; 
}

.invalid-feedback-manual {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #fc4b6c;
}

.pointer {
  cursor: pointer;
}

body.modal-open .blurrable-container{
  -webkit-filter: blur(1.5px);
  -moz-filter: blur(1.5px);
  -o-filter: blur(1.5px);
  -ms-filter: blur(1.5px);
  filter: blur(1.5px);
}

.modal-backdrop.show {
  opacity: 0.08 !important; 
}

.modal-content {
  border: none !important;
}

.updated-event-title  {
  color: black;
  font-size: xx-large;
  font-weight: bold;
}

.item-content{
	position: relative;
	padding:15px;
	cursor: pointer;
	border-bottom:1px solid rgba(0,0,0,0.1);
}

.item-title{
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}
.line-brd{
	position: absolute;;
	width: 5px;
    height: 100%;
    left: 0;
    top: 0;
}
.item{
	position: relative;
	display: block;
}

.editable-label {
  display: block;
  width: 100%;
  min-height: 38px;
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.form-control {
  background-color: hsl(0,0%,100%);
  border-color: hsl(0,0%,80%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 38px;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 300;
  line-height: 1.5;
  color: #525f7f;
  background-clip: padding-box;
  box-shadow: none;
  // transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-transition: all 100ms;
  transition: all 100ms;
}

.form-control:focus {
  box-shadow: 0 0 0 1px #8898aa;
  border-color: #8898aa;
  border-width: 1px;
}

.form-control:disabled {
  background-color: hsl(0,0%,95%);
  border-color: hsl(0,0%,90%);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  cursor: default;
  opacity: 1;
}

.bumped-z-index {
  z-index: 100;
  position: absolute;  
}

.padding-unset {
  padding: unset;
}


.unset-overflow-and-position {
  overflow: unset !important;
  position: unset !important;
}

.month-container tr {
  height: unset;
}

// update header styles for rc-year-calendar
.calendar .calendar-header {
  width: unset !important;
  margin-bottom: unset !important;
  border: unset !important;
}

.bg-secondary .item-title {
  color: white;
}

.bg-secondary .message-title {
  color: white !important;
}

.item-activated {
  background: #f6f9fc;
}

.no-background {
  background: unset;
}