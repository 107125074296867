// Sidebar
$sidebar-link-padding: 12px 13px;
$sidebar-icon-size: 20px;
$sidebar-first-level-padding: 0 0 10px 0;
$sidebar-first-level-link-padding: 7px 15px;
$sidebar-first-level-icon-size: 14px;
$sidebar-second-level-padding: 0 0 0px 0px;
/*******************
Main sidebar
******************/

.left-sidebar {
  position: absolute;
  width: $sidebar-width-full;
  height: 100%;
  top: 0px;
  z-index: 10;
  padding-top: 64px;
  background: $sidebar;
  box-shadow: $shadow;
  transition: 0.2s ease-in;
}

/*******************
sidebar navigation
******************/
.sidebar-nav {
  ul {
    .sidebar-item {
      width: $sidebar-width-full;
      .sidebar-link {
        color: $sidebar-text;
        padding: $sidebar-link-padding;
        display: flex;
        white-space: nowrap;
        align-items: center;
        line-height: 25px;
        font-weight: inherit;
        font-size: 15px;
        opacity: 0.6;
        cursor: pointer;
        i {
          font-style: normal;
          width: 27px;
          line-height: 25px;
          font-weight: inherit;
          font-size: $sidebar-icon-size;
          color: $sidebar-icons;
          display: inline-block;
          text-align: center;
        }
        &.active {
          opacity: 1;
          font-weight: 500;
        }
        &:hover {
          opacity: 1;
        }
      }
      &.selected > .sidebar-link {
        background: $success;
        color: $white;
        border-radius: 4px;
        opacity: 1;
      }
      &:hover > .sidebar-link {
        /*background: rgba(0, 0, 0, 0.04);*/
        opacity: 1;
      }
      /*******************
      First level
      ******************/
      .first-level {
        padding: $sidebar-first-level-padding;
        .sidebar-item.selected > .sidebar-link {
          background: transparent;
          color: $white;
          opacity: 1;
          font-weight: 500;
        }

        .sidebar-item .sidebar-link {
          padding: $sidebar-first-level-link-padding;
          i {
            font-size: $sidebar-first-level-icon-size;
            visibility: visible;
          }
        }
      }
      .second-level .sidebar-item {
        padding: $sidebar-second-level-padding;
      }
    }
    /*******************
         Small cap
         ******************/
    .nav-small-cap {
      font-size: 12px;
      padding: $sidebar-link-padding;
      white-space: nowrap;
      display: flex;
      align-items: center;
      line-height: 30px;
      margin-top: 10px;
      color: $sidebar-text;
      opacity: 1;
      text-transform: uppercase;
      i {
        line-height: 30px;
        margin: 0 5px;
      }
    }
  }
}
/*******************
 common m-p removed
******************/
.sidebar-nav {
  ul {
    margin: 0px;
    padding: 0px;
    li {
      list-style: none;
    }
  }
}
.collapse.in {
  display: block;
}
/*******************
Down arrow
******************/
.sidebar-nav .has-arrow {
  position: relative;
  &::after {
    position: absolute;
    content: "";
    width: 7px;
    height: 7px;
    border-width: 1px 0 0 1px;
    border-style: solid;
    border-color: $sidebar-text;
    margin-left: 10px;
    font-weight: inherit;
    -webkit-transform: rotate(135deg) translate(0, -50%);
    -ms-transform: rotate(135deg) translate(0, -50%);
    -o-transform: rotate(135deg) translate(0, -50%);
    transform: rotate(135deg) translate(0, -50%);
    -webkit-transform-origin: top;
    -ms-transform-origin: top;
    -o-transform-origin: top;
    transform-origin: top;
    top: 23px;
    right: 15px;
    -webkit-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    transition: all 0.3s ease-out;
  }
}

/*.sidebar-nav .active > .has-arrow::after,*/

/*.sidebar-nav li.selected > .has-arrow::after,*/
.sidebar-nav li.active > .has-arrow::after,
.sidebar-nav li > .has-arrow.active::after,
.sidebar-nav .has-arrow[aria-expanded="true"]::after {
  -webkit-transform: rotate(-135deg) translate(0, -50%);
  -ms-transform: rotate(-135deg) translate(0, -50%);
  -o-transform: rotate(-135deg) translate(0, -50%);
  transform: rotate(-135deg) translate(0, -50%);
}

@include media-breakpoint-down(sm) {
  .scroll-sidebar {
    height: calc(100% - 20px);
    overflow: auto;
  }
}
