// App

/*============================================================== 
 For all pages 
 ============================================================== */

#main-wrapper {
  width: 100%;
  overflow: hidden;
}

.page-wrapper {
  background: $body-bg;
  position: relative;
  transition: 0.2s ease-in;
  display: none;
}

.page-wrapper > .page-content {
  padding: 30px 15px;
  min-height: calc(100vh - 180px);
}
.page-breadcrumb {
  padding: 30px 30px 0px;
}
/*******************
 Footer
*******************/

.footer {
  padding: 15px 20px;
}

/*******************
 Stickey inner-left-part
*******************/

.left-part {
  height: calc(100vh - 172px);
  width: $left-part-width;
  position: absolute;
  overflow: hidden;
  border-right: 1px solid $border-color;
  .filter-listing {
    height: calc(100vh - 242px);
    overflow: auto;
  }
}

.right-part {
  width: calc(100% - 200px);
  height: calc(100vh - 176px);
  margin-left: 260px;

  &.app-drawer-content {
    width: calc(100% - 260px);
    margin-left: 260px;
  }
}

.right-part.chat-list {
  height: calc(100vh - 168px);
}

.right-left-part {
  width: 300px;
  position: absolute;
  border-right: 1px solid $border-color;

  .listing-over {
    height: calc(100vh - 258px);
    overflow: auto;
  }

  .listing-email-over {
    height: calc(100vh - 294px);
    overflow: auto;
  }
}

.right-right-part {
  width: calc(100% - 300px);
  height: calc(100vh - 175px);
  overflow: auto;
  margin-left: 300px;
}

.reverse-mode {
  .left-part {
    right: 0;
    border-left: 1px solid $border-color;
  }
  .show-left-part {
    right: auto;
    left: -41px;
  }
  .right-part {
    margin-left: 0px;
    margin-right: $left-part-width;
  }
}

@include media-breakpoint-down(sm) {
  #main-wrapper {
    &[data-sidebartype="mini-sidebar"] {
      .left-sidebar {
        left: -$sidebar-width-full;
      }
    }
    &.show-sidebar {
      .left-sidebar {
        left: 0;
      }
    }
  }
  .left-part {
    left: -$left-part-width;
    background: $body-bg;
    z-index: 1;
    top: $topbar-height;
    height: calc(100vh - 66px);
    box-shadow: 0 0 50px rgba(0, 0, 0, 0.2);
    position: fixed;
    transition: 0.1s ease-in;
    &.show-panel {
      left: 0px;
    }
  }
  .reverse-mode .left-part {
    right: -$left-part-width;
    left: auto;
    &.show-panel {
      right: 0px;
    }
  }
  .right-part {
    width: 100%;
    margin-left: 0px;
    height: 500px;
  }
}
